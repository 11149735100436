// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-generic-page-js": () => import("./../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-data-collection-opt-out-request-js": () => import("./../src/pages/privacy-data-collection-opt-out-request.js" /* webpackChunkName: "component---src-pages-privacy-data-collection-opt-out-request-js" */),
  "component---src-pages-unsupported-js": () => import("./../src/pages/unsupported.js" /* webpackChunkName: "component---src-pages-unsupported-js" */)
}

